import React from 'react'

import { formatPrice, PATH } from '../../../../constants/global'
import { TABLE } from '../../../../constants/tables'
import { renderDateOrNothing } from '../../../../common/utils/functions'
import { TrueOrFalse } from '../../../common'
import * as tableStyles from '../../../common/table/table.module.scss'

export const BASIC_TABLE = {
   basicPath: PATH.AUDIT_HISTORY,
   downloadPath: PATH.AUDIT_HISTORY.DOWNLOAD,
   downloadCsvText: 'Export to Excel',
   extraClassName: 'table-basic',
   header: 'Title', // TBA
   key: 'ExportAuditItems', // TBA
   modalTitle: 'Download Excel File',
   placeholder: ' audit history',
   tableTitle: TABLE.TITLE.AUDIT_HISTORY,
   primaryActionText: 'Export',
   secondaryActionText: 'Cancel',
   exportFileName: 'AuditHistory.xlsx',
   columnHeaders: [
      {
         Header: 'Audit Title',
         accessor: 'AuditTitle',
         Cell: (cell: { row: { original: { AuditTitle: string } } }) => (
            <div className={tableStyles.tableColumnCellWrapper}>
               {cell.row.original.AuditTitle}
            </div>
         ),
         width: 200,
         minWidth: 20,
      },
      {
         Header: 'Sent Date',
         accessor: 'DateSent',
         align: 'center',
         width: 120,
         minWidth: 20,
         Cell: renderDateOrNothing,
      },
      {
         Header: 'Due Date',
         accessor: 'DateDue',
         align: 'center',
         width: 120,
         minWidth: 20,
         Cell: renderDateOrNothing,
      },
      {
         Header: 'Status',
         accessor: 'AuditStatusDesc',
         align: 'center',
         width: 130,
         minWidth: 20,
      },
      {
         Header: 'Audited By',
         accessor: 'AuditAssignedToUser',
         align: 'center',
         width: 200,
         minWidth: 20,
      },
      {
         Header: 'Total Items',
         accessor: 'ItemTotal',
         align: 'center',
         width: 75,
         minWidth: 20,
      },
      {
         Header: 'Total Unconfirm',
         accessor: 'ItemsUnconfirmed',
         align: 'center',
         width: 75,
         minWidth: 20,
      },
      {
         Header: 'Total Confirm',
         accessor: 'ItemsConfirmed',
         align: 'center',
         width: 75,
         minWidth: 20,
      },
      {
         Header: 'Item Name',
         accessor: 'Title',
         Cell: (cell: { row: { original: { Title: string } } }) => (
            <div className={tableStyles.tableColumnCellWrapper}>
               {cell.row.original.Title}
            </div>
         ),
         width: 150,
         minWidth: 20,
      },
      {
         Header: 'Confirmed',
         accessor: 'IsConfirmed',
         align: 'center',
         width: 100,
         minWidth: 20,
         Cell: ({ row }) => <TrueOrFalse status={row?.original?.IsConfirmed} />,
      },
      {
         Header: 'Quantity',
         accessor: 'AuditQuantity',
         align: 'center',
         width: 75,
         minWidth: 20,
      },
      {
         Header: 'Confirmation Type',
         accessor: 'Confirmation',
         align: 'center',
         width: 100,
         minWidth: 20,
      },
      {
         Header: 'Condition',
         accessor: 'ConditionDesc',
         align: 'center',
         width: 100,
         minWidth: 20,
      },
      {
         Header: 'Note',
         accessor: 'AuditNote',
         Cell: (cell: { row: { original: { AuditNote: string } } }) => (
            <div className={tableStyles.tableColumnCellWrapper}>
               {cell.row.original.AuditNote}
            </div>
         ),
         width: 250,
         minWidth: 20,
      },
      {
         Header: 'Item Type',
         accessor: 'ToolTypeDesc',
         align: 'center',
         width: 150,
         minWidth: 20,
      },
      {
         Header: 'Item Detail',
         accessor: 'Description',
         Cell: (cell: { row: { original: { Description: string } } }) => (
            <div className={tableStyles.tableColumnCellWrapper}>
               {cell.row.original.Description}
            </div>
         ),
         width: 250,
         minWidth: 20,
      },
      {
         Header: 'Manufacturer',
         accessor: 'ManufacturerName',
         align: 'center',
         width: 150,
         minWidth: 20,
      },
      {
         Header: 'Model No.',
         accessor: 'ModelNumber',
         align: 'center',
         width: 150,
         minWidth: 20,
      },
      {
         Header: 'Serial No.',
         accessor: 'SerialNumber',
         align: 'center',
         width: 120,
         minWidth: 20,
      },
      {
         Header: 'Barcode',
         accessor: 'ItemBarcode',
         align: 'center',
         width: 120,
         minWidth: 20,
      },
      {
         Header: 'Category',
         accessor: 'CategoryName',
         align: 'center',
         width: 150,
         minWidth: 20,
      },
      {
         Header: 'Item Status',
         accessor: 'ItemStatusDesc',
         align: 'center',
         width: 120,
         minWidth: 20,
      },
      {
         Header: 'Item Status Quantity',
         accessor: 'StatusQuantity',
         align: 'center',
         width: 75,
         minWidth: 20,
      },
      {
         Header: 'Item Total Quantity',
         accessor: 'TotalQuantity',
         align: 'center',
         width: 75,
         minWidth: 20,
      },
      {
         Header: 'Loaned/Pending To',
         accessor: 'AssignedToUser',
         align: 'center',
         width: 150,
         minWidth: 20,
      },
      {
         Header: 'Assigned To Kit',
         accessor: 'AssignedToKitName',
         align: 'center',
         width: 150,
         minWidth: 20,
      },
      {
         Header: 'Purchase Date',
         accessor: 'PurchaseDate',
         align: 'center',
         width: 110,
         minWidth: 20,
         Cell: renderDateOrNothing,
      },
      {
         Header: 'Purchase Price',
         accessor: 'PurchasePrice',
         align: 'center',
         width: 110,
         minWidth: 20,
      },
      {
         Header: 'Warranty Date',
         accessor: 'WarrantyDate',
         align: 'center',
         width: 110,
         minWidth: 20,
         Cell: renderDateOrNothing,
      },
      {
         Header: 'Rental Tool',
         accessor: 'IsRentalTool',
         width: 100,
         minWidth: 20,
      },
      {
         Header: 'Rental Start Date',
         accessor: 'RentalDate',
         align: 'center',
         width: 100,
         minWidth: 20,
         Cell: renderDateOrNothing,
      },
      {
         Header: 'Rental End Date',
         accessor: 'RentalReturnDate',
         align: 'center',
         width: 100,
         minWidth: 20,
         Cell: renderDateOrNothing,
      },
      {
         Header: 'Rate-Day',
         accessor: 'CustomRentalRate1',
         align: 'center',
         width: 110,
         minWidth: 20,
         Cell: ({ row }) => formatPrice(row?.original?.CustomRentalRate1),
      },
      {
         Header: 'Rate-Week',
         accessor: 'CustomRentalRate2',
         align: 'center',
         width: 110,
         minWidth: 20,
         Cell: ({ row }) => formatPrice(row?.original?.CustomRentalRate2),
      },
      {
         Header: 'Monthly Rate',
         accessor: 'CustomRentalRate3',
         align: 'center',
         width: 110,
         minWidth: 20,
         Cell: ({ row }) => formatPrice(row?.original?.CustomRentalRate3),
      },
      {
         Header: 'Hourly Rate',
         accessor: 'CustomRentalRate4',
         align: 'center',
         width: 110,
         minWidth: 20,
         Cell: ({ row }) => formatPrice(row?.original?.CustomRentalRate4),
      },
      {
         Header: 'Custom Date 1 Value',
         accessor: 'CustomDate1Value',
         align: 'center',
         width: 120,
         minWidth: 20,
         Cell: renderDateOrNothing,
      },
      {
         Header: 'Custom Date 2 Value',
         accessor: 'CustomDate2Value',
         align: 'center',
         width: 120,
         minWidth: 20,
         Cell: renderDateOrNothing,
      },
      {
         Header: 'Custom Text 1 Value',
         accessor: 'CustomText1Value',
         width: 150,
         minWidth: 20,
      },
      {
         Header: 'Custom Text 2 Value',
         accessor: 'CustomText2Value',
         width: 150,
         minWidth: 20,
      },
      {
         Header: 'Custom URL 1',
         accessor: 'CustomURL1Value',
         Cell: (cell: { row: { original: { CustomURL1Value: string } } }) => (
            <div className={tableStyles.tableColumnCellWrapper}>
               <a href={cell.row.original.CustomURL1Value} target={'_blank'}>
                  {cell.row.original.CustomURL1Value}
               </a>
            </div>
         ),
         width: 130,
         minWidth: 20,
      },
      {
         Header: 'Custom URL 2',
         accessor: 'CustomURL2Value',
         Cell: (cell: { row: { original: { CustomURL2Value: string } } }) => (
            <div className={tableStyles.tableColumnCellWrapper}>
               <a href={cell.row.original.CustomURL2Value} target={'_blank'}>
                  {cell.row.original.CustomURL2Value}
               </a>
            </div>
         ),
         width: 130,
         minWidth: 20,
      },
      {
         Header: 'Website URL',
         accessor: 'WebsiteUrl',
         Cell: (cell: { row: { original: { WebsiteUrl: string } } }) => (
            <div className={tableStyles.tableColumnCellWrapper}>
               <a href={cell.row.original.WebsiteUrl} target={'_blank'}>
                  {cell.row.original.WebsiteUrl}
               </a>
            </div>
         ),
         width: 130,
         minWidth: 20,
      },
      {
         Header: 'Owners URL',
         Cell: (cell: { row: { original: { OwnersUrl: string } } }) => (
            <div className={tableStyles.tableColumnCellWrapper}>
               <a href={cell.row.original.OwnersUrl} target={'_blank'}>
                  {cell.row.original.OwnersUrl}
               </a>
            </div>
         ),
         width: 130,
         minWidth: 20,
      },
      {
         Header: 'Return Warehouse',
         accessor: 'DefaultLocation',
         Cell: (cell: { row: { original: { DefaultLocation: string } } }) => (
            <div className={tableStyles.tableColumnCellWrapper}>
               {cell.row.original.DefaultLocation}
            </div>
         ),
         width: 200,
         minWidth: 20,
      },
   ],
   initialPayload: {
      searchForAuditItem: '',
      sentStartDate: null,
      sentEndDate: null,
      selectedConditions: [],
      selectedConfrmationType: [],
      selectedItemConfirmation: 0,
      offset: -1,
      selectedConnections: [],
      selectedLocations: [],
      selectedCategories: [],
      selectedStatus: [],
      selectedManufacturers: [],
      skip: 0,
      take: 10,
   },
   downloadRequest: {
      searchForAuditItem: '',
      sentStartDate: null,
      sentEndDate: null,
      selectedConditions: [],
      selectedConfrmationType: [],
      selectedItemConfirmation: 0,
      offset: -1,
      selectedConnections: [],
      selectedLocations: [],
      selectedCategories: [],
      selectedStatus: [],
      selectedManufacturers: [],
      skip: 0,
      take: 0,
   },
}
